import { db } from '@/firebase/config'
import wrapWithCache from '@/composables/wrapWithCache'

const getMyPostOpps = async (store, userId) => {
  try {
    await db.collection("Opportunities").where("alumniId", "==", userId).get().then(docs => {
      let result = []
      docs.forEach(doc => {
        result.push({ ...doc.data(), id: doc.id })
      });
      store.value = result
      // console.log("getMyPostOpps activated")
    })
  } catch (error) {
    alert("getMyPostOpps: " + error)
  }
};

export default wrapWithCache(getMyPostOpps, "myPostOpps")
