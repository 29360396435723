import { db } from '@/firebase/config'

const getWish = async (store, companyId, wishId) => {
  try {
    store.value = null;
    await db.collection("Companies").doc(companyId).collection("Wishes").doc(wishId).get().then(async doc => {
      let candidates = [];
      await doc.ref.collection("Candidates").get().then(docs => {
        docs.forEach(doc => {
          candidates.push({...doc.data(), candidateId: doc.id});
        })
      })
			store.value = {...doc.data(), companyId: companyId, wishId: doc.id, candidates: JSON.parse(JSON.stringify(candidates))};
			// console.log(store.value)
    });
    // console.log("getWish activated");
  } catch (error) {
    alert("getWish:" + error);
  }

};

export default getWish
