import { db } from '@/firebase/config'

const getMyAcceptedWishes = async (store, uid, isDetail) => {
  try {
		store.value = [];
    if (isDetail){
      await db.collection("Profiles").doc(uid).collection("myAcceptedWishes").orderBy("time","desc").get().then(docs => {
        docs.forEach(doc => {
          store.value.push({ ...doc.data(), myAcceptedWishId: doc.id});
        })
      });
    } else {
      await db.collection("Profiles").doc(uid).collection("myAcceptedWishes").orderBy("time","desc").limit(3).get().then(docs => {
        docs.forEach(doc => {
          store.value.push({ ...doc.data(), myAcceptedWishId: doc.id});
        })
      });
    }
  } catch (error) {
    alert("getMyAcceptedWishes: " + error)
  }
};

export default getMyAcceptedWishes
