import { db } from '@/firebase/config'

const getCompany = async (store, companyId) => {
  try {
    await db.collection("Companies").doc(companyId).get().then(doc => {
      store.value = { ...doc.data(), id: doc.id }
    })
  } catch (error) {
    alert("getCompany " + error)
  }

};

export default getCompany
